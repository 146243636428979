.badgeContainer {
    box-sizing: border-box;
    border-radius: 17px;
    font-size: var(--xsmall-font-size);
}

.flat {
    background: transparent;
    color: var(--white-color);
    border: 1px solid var(--white-color);
}

.filled {
    background: var(--violet-color);
    color: var(--black-color);
}

.disabled {
    background: var(--grey-color);
    color: var(--black-color);
}

.black {
    background: var(--black-color);
    color: var(--white-color);
}

.rounded {
    border-radius: 50%;
}

.clickeable {
    cursor: pointer;
}

.clickeable:hover {
    opacity: 0.9;
}
