.chooseKongModalContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.separator {
    width: 100%;
    height: 1px;
    background: var(--grey-color);
}

.header {
    font-size: var(--font-size-20);
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid var(--grey-color);
    padding-bottom: 32px;
}

.chooseKongModalContent button {
    margin-top: 25px;
}

.chooseKongModalContent button:active {
    margin-top: 27px;
    margin-bottom: -2px;
}

.chooseKongModalContent span {
    font-size: var(--xregular-font-size);
    font-weight: bold;
}

.emptyKongs {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--xregular-font-size);
    font-weight: bold;
}

.bottomButtons button {
    margin: 30px 10px;
    cursor: pointer;
}

.bottomButtons button:active {
    margin: 30px 10px;
    margin-top: 32px;
    margin-bottom: 28px;
    cursor: pointer;
}

.kongsList {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.kongsListInner {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: grid;
    grid-gap: 32px;
    transform: translate3d(0, 0, 0);
    grid-template-columns: 145px 145px 145px 145px 145px;
    box-sizing: border-box;
    padding: 20px 32px;
}

.kongItem {
    cursor: pointer;
    position: relative;
}

.kongItemSelected {
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 9;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--black-color);
}

.bottomButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media (max-width: 950px) {
    .kongsListInner {
        grid-gap: auto;
        grid-template-columns: 180px 180px 180px 180px;
    }
}

@media (max-width: 650px) {
    .kongsListInner {
        position: relative;
        overflow: hidden;
        width: 100%;
        display: grid;
        grid-gap: 18px;
        transform: translate3d(0, 0, 0);
        grid-template-columns: auto auto auto;
        box-sizing: border-box;
        padding: 20px 18px;
    }
    .header {
        font-size: var(--font-size-16);
    }
}
