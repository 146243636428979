.overlay {
    background: black;
    opacity: 0.5;
    z-index: 999;
    width: 100%;
    height: auto;
}
.wrapper {
    width: 100%;
    height: auto;
    border: 1px solid var(--grey-color);
    border-radius: 12px;
    cursor: pointer;
}

.topOnlyWrapper {
    position: relative;
    width: 100%;
    max-width: 180px;
    height: auto;
    border-radius: 8px;
    background-color: var(--black-color);
    border: 1px solid var(--grey-color);
    cursor: pointer;
}

.topOnlyWrapper:hover {
    opacity: 0.8;
}

.whiteBorder {
    border: 1px solid var(--white-color);
}

.whiteBorder:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 2px solid var(--white-color);
    border-radius: 8px;
    box-sizing: border-box;
}

.viewOnlyWrapper,
.viewOwnedWrapper,
.viewOfferedWrapper,
.viewMemberWrapper {
    width: 100%;
    height: auto;
    border-radius: 12px;
    background-color: var(--black-color);
    border: 1px solid var(--grey-color);
    cursor: pointer;
}

.imageWrapper {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: -1px;
    padding: 14px 14px 5px 14px;
    box-sizing: border-box;
}

.image {
    border-radius: 10px;
    width: 100%;
}

.topOnlyImage {
    border-radius: 8px 8px 0px 0px;
    width: 100%;
}

.TopOnlyTitleWrapper {
    padding: 9px;
    margin-top: -4px;
    text-align: left;
}

.infoArea {
    position: relative;
    display: flex;
    flex-direction: column;
}

.infoAreaInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 14px 14px 14px;
    /* border-bottom: 1px solid var(--grey-color); */
}

.divider {
    width: 90%;
    border-top: 1px solid var(--grey-color);
    height: 1px;
    align-self: center;
}

.title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-20);
    font-weight: 700;
    color: var(--white-color);
    margin-bottom: 8px;
}

.ellipsisWrapper {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80%;
}

.smallTitle {
    font-size: var(--font-size-12);
    color: var(--white-color);
    font-weight: 700;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.smallSubTitle {
    font-weight: 600;
    color: var(--violet-color);
    font-size: var(--font-size-12);
}

.properties {
    font-size: var(--font-size-16);
    font-weight: 700;
    color: var(--violet-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.properties span {
    color: var(--white-color);
}

.properties a {
    color: var(--white-color);
    text-decoration: none;
}

.properties a:hover {
    text-decoration: underline;
}

.attributes {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 18px;
}

.attributesNoBorder {
    border: none;
}

.singleAttribute {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: var(--white-color);
    align-items: center;
}

.attributeName {
    font-size: var(--font-size-14);
    font-weight: 700;
    color: var(--grey-color-2);
}

.attributeValue {
    font-size: var(--font-size-24);
    font-weight: 500;
    color: var(--white-color);
}

.buttonArea {
    display: flex;
    width: auto;
    border-radius: 0px 0px 12px 12px;
    margin: 0 -1px -1px -1px;
}

.ownedButton {
    height: 63px;
    width: 100%;
    color: var(--white-color);
    font-size: var(--font-size-16);
    font-weight: 700;
    background-color: var(--black-color);
    border-radius: 0px 0px 12px 12px;
    border: solid 1px var(--grey-color);
}

.ownedButton:hover,
.ownedButton:active {
    color: var(--black-color);
    background-color: var(--white-color);
    cursor: pointer;
}

.ownedBtnRemoved {
    background-color: var(--violet-color);
    height: 59px;
    width: 100%;
    color: var(--black-color);
    border-radius: 0px 0px 12px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-16);
    font-weight: 700;
}

.accepted {
    background-color: var(--green-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 12px 12px;
    height: 60px;
    font-size: var(--font-size-16);
    color: var(--black-color);
}

.rejected {
    background-color: var(--violet-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 12px 12px;
    height: 60px;
    font-size: var(--font-size-16);
}

.btn {
    width: 50%;
    height: 60px;
    color: var(--white-color);
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0px 0px;
    border: solid 1px var(--grey-color);
    background-color: var(--black-color);
    cursor: pointer;
}

.btn:hover,
.btn:active {
    background-color: var(--white-color);
    color: var(--black-color);
}

.btnLeft {
    border-radius: 0px 0px 0px 12px;
}

.btnRight {
    border-radius: 0px 0px 12px 0px;
    margin-left: -1px;
}

.hidden {
    visibility: hidden;
}

@media screen and (max-width: 650px) {
    .infoAreaInner {
        padding: 10px 8px;
    }

    .imageWrapper {
        width: 92%;
        border-radius: 10px;
        margin: 0 8px -13px 8px;
        padding: 0;
        box-sizing: border-box;
    }

    .title {
        font-size: var(--font-size-14);
        color: var(--white-color);
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .properties {
        color: var(--violet-color);
        font-size: var(--font-size-12);
        margin: 0;
    }

    .divider {
        margin-top: -3px;
        padding: 0;
    }

    .attributes {
        height: 42px;
        padding: 0 9px;
    }

    .singleAttribute {
        flex-direction: row;
        margin-right: 0;
    }

    .attributeIcon {
        width: 14.55;
        height: 14.55;
        margin-right: 1.6px;
    }

    /* override inline icon style */
    /* TBD: removed when icons is fixed */
    .attributeName svg {
        margin-left: 0! important;
    }

    .attributeValue {
        font-size: var(--font-size-10);
    }
    .smallTitle {
        font-size: var(--font-size-12);
    }
}

@media screen and (max-width: 600px) {
    .attributeName {
        font-size: var(--font-size-12);
    }
    .viewOwnedWrapper,
    .viewOfferedWrapper,
    .viewMemberWrapper {
        width: 100%;
        height: 100%;
    }
    .viewOnlyWrapper {
        height: auto;
        width: 100%;
        border-radius: 12px;
        background-color: var(--black-color);
        border: 1px solid var(--grey-color);
    }
    .ellipsisWrapper {
        width: 100px;
    }
}
