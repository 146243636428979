.footer {
    position: relative;
    z-index: 1;
}

.inner {
    width: 100%;
    max-width: 1600px;
    padding: 32px;
    margin: 0 auto;
}

.caption {
    margin-bottom: 24px;
}

.gutter {
    margin-bottom: 32px;
}

.social a {
    margin-left: 32px;
}

.social a:first-child {
    margin-left: 0;
}

.menuItem {
    margin-bottom: 24px;
}

.menuItem:last-child {
    margin-bottom: 0;
}

.copyright {
    border-top: 1px solid #dedede;
    padding: 18px 0;
}

.row {
    display: flex;
    align-items: baseline;
}

.calFirst {
    width: 50%;
}
.calSecond {
    width: 25%;
}

@media screen and (max-width: 576px) {
    .caption {
        text-align: center;
    }
    .row {
        display: block;
    }
    .calFirst,
    .calSecond {
        width: 100%;
        justify-content: center;
        text-align: center;
        margin-bottom: 32px;
    }
    .social {
        justify-content: center;
    }
}
@media (max-width: 506px) {
    .inner {
        width: 100%;
        max-width: 1600px;
        padding: 32px 18px;
        margin: 0 auto;
    }
}
