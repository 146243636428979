.button {
    position: relative;
    display: inline-block;
    line-height: 1;
    outline: none;
    border: 0;
    cursor: pointer;
}

.sizeNormal {
    padding: 24px;
    border-radius: 40px;
}

.sizeSmall {
    padding: 16px;
    border-radius: 30px;
    min-width: 80px;
    text-align: center;
}

.sizeXSmall {
    padding: 8px 16px;
    border-radius: 16px;
}

.sizeXSmall {
    padding: 8px 16px;
    border-radius: 16px;
}

.sizeXSmall {
    padding: 8px 16px;
    border-radius: 16px;
}

.sizeMedium {
    padding: 16px;
    border-radius: 30px;
}

.disabled {
    opacity: 0.8;
    background-color: #9d99a1;
    cursor: default;
}

@media screen and (max-height: 500px) {
    .sizeNormal {
        padding: 12px 16px;
        border-radius: 30px;
    }
}
