.changeClubNameModalContent {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.title {
    font-size: var(--font-size-20);
    color: var(--white-color);
    font-weight: bold;
    margin-bottom: 10px;
}

.whiteText {
    color: var(--white-color)
}

.description {
    font-size: var(--font-size-18);
}

.changeClubNameModalContent button {
    margin-top: 25px;
}

.changeClubNameModalContent button:active {
    margin-top: 27px;
}

.inputContainer {
    width: 80%;
    margin-top: 35px;
}

@media (max-width: 900px) {
    .inputContainer {
        width: 98%;
        margin-right: auto;
        margin-left: auto;
    }
}
