.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    border-bottom: 1px solid #494a51;
    transition: transform 0.5s ease;
    transform: translate(0, 0);
}

.headerHidden {
    transform: translate(0, -91px);
}

.header_inner {
    width: 100%;
    height: 93px;
    padding: 20px 32px;
    margin: 0 auto;
}

.header_title {
    padding-left: 20px;
}

.header_space_between_menu {
    width: 32px;
}

.header_menu_element {
    cursor: pointer;
}

.headerMenu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: #b87af7;
    height: 100%;
    width: 500px;
    transition: transform 0.5s ease-in;
    transform: translate(-1000px, 0);
}

.headerMenu.desktop {
    display: none;
}

.open {
    transform: translate(0, 0);
}

.menuClose {
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px;
    cursor: pointer;
}

.menuContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 40px;
}

.socialContainer {
    display: flex;
    align-items: center;
    border-left: 1px solid #494a51;
    border-right: 1px solid #494a51;
    padding: 0 20px;
    margin-right: 20px;
}

.socialContainer a {
    margin: 0 18px;
}

.socialContainer svg path {
    fill: #fff;
}

.menuItem {
    margin-bottom: 30px;
    white-space: nowrap;
}

.inlineMenuContainer {
    display: flex;
    align-items: center;
    border-left: 1px solid #494a51;
    padding-left: 0;
    margin-left: 20px;
}

.inlineMenuItem {
    margin: 0 18px;
}

.connectWalletBtn {
    margin-right: 12px;
}

.connectAddr {
    border: 1px solid #fff;
    padding: 10px 12px;
    border-radius: 12px;
}

@media screen and (max-width: 1350px) {
    .socialContainer a {
        margin: 0 12px;
    }
    .inlineMenuItem {
        margin: 0 12px;
        font-size: 18px;
    }
    .header_title {
        font-size: 18px;
    }
}

@media screen and (max-width: 1200px) {
    .socialContainer a {
        margin: 0 6px;
    }
    .socialContainer svg {
        height: 24px;
    }
    .inlineMenuItem {
        margin: 0 6px;
        font-size: 16px;
    }
    .inlineMenuContainer {
        padding-left: 6px;
    }
    .socialContainer {
        padding: 0 6px;
    }
}

@media screen and (max-width: 1050px) {
    .inlineMenuContainer {
        margin-left: 6px;
    }
    .header_title {
        padding-left: 12px;
    }
    .inlineMenuItem {
        margin: 0 4px;
        font-size: 14px;
    }
    .socialContainer a {
        margin: 0;
    }
    .socialContainer svg {
        height: 20px;
    }
    .socialMobile {
        display: flex;
        align-items: center;
        border-left: 1px solid #494a51;
        border-right: 1px solid #494a51;
        padding: 0 20px 24px;
        text-align: center;
        justify-content: center;
    }
    .socialMobile a {
        margin: 0 12px;
    }
    .socialMobile path {
        fill: #000000;
    }
}

@media screen and (max-width: 900px) {
    .inlineMenuContainer {
        display: none;
    }
    .socialContainer {
        display: none;
    }
    .headerMenu {
        width: 100%;
        background: #ec6ebe;
        display: flex;
        flex-direction: column;
    }
    .menuContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .menuButtons {
        width: 100%;
        padding: 0 30px 30px;
    }
    .menuButtons a {
        display: block;
        width: 100%;
        margin-bottom: 12px;
        box-sizing: border-box;
        text-align: center;
    }
}

@media screen and (max-width: 576px) {
    .headerMenu {
        width: 100%;
        background: #ec6ebe;
        display: flex;
        flex-direction: column;
    }
    .menuClose {
        margin: 48px 30px;
        cursor: pointer;
    }
    .menuContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .menuButtons {
        width: 100%;
        padding: 0 30px 30px;
    }
    .menuButtons a {
        display: block;
        width: 100%;
        margin-bottom: 12px;
        box-sizing: border-box;
        text-align: center;
    }
    .menuTitle {
        text-align: center;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        padding: 47px 0;
    }
    .inlineMenuContainer {
        display: none;
    }
    .socialContainer {
        display: none;
    }
}

@media screen and (max-height: 500px) {
    .header {
        transform: translate(0, -91px);
    }
}

@media (max-width: 506px) {
    .header_inner {
        padding: 20px 18px;
    }
}