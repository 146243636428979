.chooseKongModalContent {
    width: 100%;
    height: 100%;
}

.chooseKongModalContent button {
    margin-top: 25px;
}

.chooseKongModalContent button:active {
    margin-top: 27px;
    margin-bottom: -2px;
}

.title {
    font-size: var(--font-size-20);
    color: var(--violet-color);
    font-weight: bold;
    margin-bottom: 10px;
}

.description {
    font-size: var(--font-size-18);
}

.imageContainer {
    width: 350px;
    height: 350px;
    overflow: hidden;
    border: 3px solid white;
    position: relative;
}

.imageContainer img {
    width: inherit;
    height: inherit;
    position: absolute;
}

.controls {
    width: 100%;
    margin-top: 20px;
}
