.dropdown {
    position: relative;
}

.placeholder {
    cursor: pointer;
    position: relative;
}

.placeholder canvas {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.placeholder.show {
    z-index: 10;
}

.menu {
    display: block;
    z-index: 9;
    position: absolute;
    top: -10px;
    right: -16px;
    border: 1px solid var(--grey-color-3);
    border-radius: 12px;
    white-space: nowrap;
    background: #000000;
    width: 180px;
}

.header {
    right: 0px;
    left: -17px;
    top: -17px;
}

.menuItem,
.menuItemList {
    font-size: inherit;
    font-weight: bold;
    color: var(--white-color);
    border-bottom: 1px solid var(--grey-color-3);
    cursor: pointer;
    padding: 16px;
}

.menuItemList {
    padding: 0px;
}

.menuItem:hover,
.menuItemList:hover .selectedMenu {
    background: var(--rose-color);
}

.submenuWrapper {
    padding: 16px;
}

.menuItem:first-child:hover {
    background: var(--black-color);
    border-radius: 12px 12px 0px 0px;
}

.menuItem:last-child {
    border-radius: 0 0 12px 12px;
    border-bottom: 0;
}

.menuExtra {
    border-radius: 0 0 12px 12px;
}

.selectedMenu:hover {
    background-color: var(--violet-color);
}

.subMenuItem {
    padding: 15px 0 15px 30px;
    font-size: var(--font-size-14);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%
}

.subMenuItem:hover {
    color: var(--violet-color);
}

.placeholderLabel {
    font-size: var(--font-size-16);
    font-weight: bold;
    padding: 20px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    padding: 16px;
}
