/* FONT */

.fontSizeXLarge {
    font-size: 60px;
    font-weight: 500;
}

.fontSizeExtraLarge {
    font-size: 40px;
    font-weight: 600;
}

.fontSizeLarge {
    font-size: 32px;
    font-weight: 400;
}

.fontSizeXMedium {
    font-size: 24px;
    font-weight: 400;
}

.fontSizeMedium {
    font-size: 20px;
    font-weight: bold;
}

.fontSizeXSmall {
    font-size: 16px;
    font-weight: 500;
}

.fontSizeMediumNormal {
    font-size: 20px;
    font-weight: 400;
}

.fontSizeNormal {
    font-size: 16px;
    font-weight: 400;
}

.lineHeightNormal {
    line-height: 1.6;
}

.fontColorWhite {
    color: #fefefe;
}

.fontColorBlack {
    color: #000000;
}

.fontColorGray {
    color: #a6a6a6;
}

.fontColorRose {
    color: #b87af7;
}

.fontColorLightGray {
    color: #c3c3c3;
}

.fontColorYellow {
    color: #feda5e;
}

/* BG */

.bgColorBlack {
    background-color: #000000;
}

.bgColorWhite {
    background-color: #ffffff;
}

.bgColorTransparent {
    background-color: transparent;
}

.bgColorRose {
    background-color: #b87af7;
}

.borderColorBlack {
    border: 1px solid #000000;
}

.textAlignLeft {
    text-align: left;
}

.page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.staticPage {
    width: 100%;
    margin-top: 94px;
    min-height: calc(100vh - 94px);
    height: 100%;
    background: #000000;
}

.staticPageInner {
    max-width: 800px;
    padding: 24px 24px 48px;
    margin: 0 auto;
}

.staticPageInnerWide {
    width: 100%;
    padding: 24px 32px 48px;
    margin: 0 auto;
}

.pageTitle {
    padding: 32px 0;
    display: flex;
    align-items: center;
    text-align: center;
}

.contentCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.roundBlock {
    position: relative;
    background: #000000;
    border: 1px solid #494a51;
    padding: 24px 0;
    border-radius: 12px;
}

.roundBlockDelimiter {
    width: 100%;
    height: 1px;
    background: #494a51;
}

/* */

.ethInputWrapper {
    position: relative;
    width: 60%;
    margin: 0 auto;
}

.ethInputIco {
    position: absolute;
    top: 1px;
    right: 1px;
    padding: 18px 8px;
    z-index: 2;
    width: 96px;
    border-radius: 0 12px 12px 0;
    background: #000000;
}

.ethInputLabel {
    font-size: 14px;
    color: #585858;
}

.ethInput {
    position: relative;
    background: #1b1b1b;
    border: 1px solid #8c8c8c;
    color: #fff;
    text-align: right;
    font-size: 38px;
    height: 96px;
    padding: 8px 110px 8px 8px;
    width: 100%;
    border-radius: 12px;
}

.baseInput {
    position: relative;
    background: #1b1b1b;
    border: 1px solid #8c8c8c;
    color: #fff;
    font-size: 18px;
    width: 100%;
    padding: 8px;
    border-radius: 12px;
}

.ethInput.error {
    border-color: #f00;
}

.errorMessage {
    color: #f00;
}

/* GRID */

.flex {
    display: flex;
}

.gridRow {
    display: flex;
    align-items: center;
}

.gridCalAlignCenter {
    text-align: center;
    justify-content: center;
}

.gridRowAlignBaseline {
    display: flex;
    align-items: baseline;
}

.spaceBetween {
    justify-content: space-evenly;
}

.mbM {
    margin-bottom: 12px;
}

.mbMl {
    margin-bottom: 16px;
}

.mbL {
    margin-bottom: 20px;
}

.mbXl {
    margin-bottom: 24px;
}

.mbXXl {
    margin-bottom: 28px;
}

.mbXXXl {
    margin-bottom: 32px;
}

.pxXl {
    padding-left: 24px;
    padding-right: 24px;
}

.gridCal1 {
    width: 10%;
}

.gridCal2 {
    width: 20%;
}

.gridCal25 {
    width: 25%;
}

.gridCal3 {
    width: 30%;
}

.gridCal5 {
    width: 50%;
}

.gridCal10 {
    width: 100%;
}

.gridBlock {
    display: block;
}

.gridSpace {
    flex-grow: 1;
}

@media screen and (max-height: 800px) {
    .fontSizeXLarge {
        font-size: 38px;
    }
    .fontSizeXMedium {
        font-size: 20px;
    }
}

@media screen and (max-height: 650px) {
    .fontSizeXLarge {
        font-size: 35px;
    }
    .fontSizeXMedium {
        font-size: 18px;
    }
}

@media screen and (max-width: 1150px) {
    .fontSizeMedium {
        font-size: 16px;
        font-weight: bold;
    }
}

@media screen and (max-width: 900px) {
    .fontSizeXLarge {
        font-size: 42px;
        font-weight: 500;
    }
}

@media screen and (max-width: 576px) {
    .fontSizeXLarge {
        font-size: 26px;
        font-weight: 500;
    }

    .fontSizeExtraLarge {
        font-size: 36px;
        font-weight: 600;
    }

    .fontSizeLarge {
        font-size: 28px;
        font-weight: 400;
    }

    .fontSizeXMedium {
        font-size: 18px;
        font-weight: 400;
    }

    .fontSizeMediumNormal {
        font-size: 13px;
        font-weight: 400;
    }
}

@media screen and (max-height: 600px) {
    .fontSizeXLarge {
        font-size: 18px;
        font-weight: 500;
    }
}

@media screen and (max-width: 280px) {
    .fontSizeXLarge {
        font-size: 18px;
        font-weight: 500;
    }
}
