.changeClubNameModalContent {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.changeClubNameModalContent button {
    margin-top: 25px;
}
.changeClubNameModalContent button:active {
    margin-top: 27px;
}

.changeClubNameModalContent span {
    font-size: var(--xregular-font-size);
    font-weight: bold;
}

.inputContainer {
    width: 80%;
    margin-top: 35px;
}
