.chooseKongModalContent {
    width: 100%;
    height: 100%;
}

.chooseKongModalContent button {
    margin-top: 25px;
}

.chooseKongModalContent button:active {
    margin-top: 27px;
    margin-bottom: -2px;
}

.title {
    font-size: var(--font-size-20);
    color: var(--white-color);
    font-weight: bold;
    margin-bottom: 10px;
}

.description {
    font-size: var(--font-size-18);
}
