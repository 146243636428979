.dialogueContainer {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dialogueText {
    font-size: var(--font-size-20);
    font-weight: 700;
    margin-bottom: 20px;
}
