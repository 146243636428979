.rangeBarContainer {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0 50px;
}

/*Top Range*/

.topRange {
    position: absolute;
    top: 2px;
    left: 0;
    padding: 0 50px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .topRange {
        width: 100%;
        -webkit-appearance: none;
        background-color: transparent;
        border-radius: 5px;
    }

    .topRange::-webkit-slider-runnable-track {
        height: 100%;
        -webkit-appearance: none;
        color: var(--violet-color);
        margin-top: -1px;
    }

    .topRange::-webkit-slider-thumb {
        width: 20px;
        -webkit-appearance: none;
        height: 20px;
        cursor: pointer;
        background: var(--white-color);
        border-radius: 50%;
    }
}
/** FF*/
.topRange::-moz-range-progress {
    background-color: transparent;
}
.topRange::-moz-range-track {
    background-color: transparent;
    border-radius: 5px;
}
/* IE*/
.topRange::-ms-fill-lower {
    background-color: transparent;
}
.topRange::-ms-fill-upper {
    border-radius: 5px;
    background-color: transparent;
}

/* Violet Range */
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .violetRange {
        overflow: hidden;
        width: 100%;
        height: 4px;
        -webkit-appearance: none;
        background-color: var(--grey-color);
        border-radius: 5px;
    }

    .violetRange::-webkit-slider-runnable-track {
        height: 4px;
        -webkit-appearance: none;
        color: var(--violet-color);
        margin-top: -1px;
    }

    .violetRange::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 10px;
        cursor: pointer;
        background: var(--white-color);
        border-radius: 50%;
        box-shadow: -1000px 0 0 1000px var(--violet-color);
    }
}
/** FF*/
.violetRange::-moz-range-progress {
    background-color: var(--violet-color);
}
.violetRange::-moz-range-track {
    background-color: var(--grey-color);
    border-radius: 5px;
}
/* IE*/
.violetRange::-ms-fill-lower {
    background-color: var(--violet-color);
}
.violetRange::-ms-fill-upper {
    border-radius: 5px;
    background-color: var(--grey-color);
}
