.zoomPanContainer {
    overflow: hidden;
    border: 1px solid var(--white-color);
    border-radius: 12px;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.dragging {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
