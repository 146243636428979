.root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    position: relative;
    transform: scale(1);
    animation: pulsAnimation 2s infinite;
}

@keyframes pulsAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}