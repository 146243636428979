.chooseKongModalContent {
    width: 100%;
    height: 100%;
}

.chooseKongModalContent span {
    font-size: var(--regular-font-size);
    font-weight: normal;
    margin-bottom: 20px;
}

.chooseKongModalContent h3 {
    margin-bottom: 20px;
}
