.dialogueContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dialogueText {
    font-size: var(--font-size-20);
    font-weight: 700;
    margin-bottom: 20px;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
}

.buttonsWrapper button {
    margin: 0 20px;
}

@media screen and (max-width: 650px) {
    .buttonsWrapper {
        flex-direction: column;
    }
    .buttonsWrapper button {
        margin-bottom: 15px;
    }
    .dialogueText {
        font-size: var(--font-size-16);
        font-weight: 700;
    }
}

@media screen and (max-width: 600px) {
    .buttonsWrapper {
        flex-direction: column;
    }
    .buttonsWrapper button {
        margin-bottom: 15px;
    }
    .dialogueText {
        font-size: var(--font-size-16);
        font-weight: 700;
    }
}
