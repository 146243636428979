.successModalContent {
    width: 100%;
    height: 100%;
    margin-top: -25px;
}

.title {
    font-size: var(--font-size-20);
    font-weight: bold;
    margin-bottom: 15px;
}

.description {
    font-size: var(--font-size-14);
    font-weight: 500;
    margin: 20px 0 36px 0;
}
