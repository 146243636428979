.textInputContainer {
    display: flex;
    flex-direction: column;
}

.inputWrapper {
    max-width: 520px;
    height: 52px;
    display: flex;
    align-items: center;
}

.textAreaWrapper {
    max-width: 520px;
    height: 160px;
}

.inputWrapper > input,
.textAreaWrapper > textarea {
    width: inherit;
    height: 100%;
    flex: 1;
    font-size: var(--xsmall-font-size);
    padding: 16px 14px;
    outline: none;
    border: none;
    border-radius: 12px;
    color: var(--white-color);
    background: var(--darkGrey-color);
    border: 1px solid var(--grey-color);
    resize: none;
    font-family: inherit;
}

.errorWrapper {
    color: var(--error-color);
    font-size: var(--xsmall-font-size);
    text-align: left;
    margin-top: 8px;
    align-items: flex-start;
    justify-content: flex-start;
}

.errorWrapper span {
    padding-left: 5px;
}

.errorInput > input,
.errorInput > textarea {
    border: 1px solid var(--error-color);
    color: var(--error-color);
}

.errorInput > input::placeholder {
    color: var(--error-color);
}

@media screen and (max-width: 900px) {
    .inputWrapper > input {
        width: 50%;
    }
}

@media screen and (max-width: 576px) {
    .inputWrapper > input {
        width: 40%;
    }
}

@media screen and (max-height: 500px) {
    .inputWrapper > input {
        width: 40%;
    }
}
