.addMemberModalContent {
    width: 100%;
    height: 100%;
}

.addMemberModalContent span {
    font-size: var(--regular-font-size);
    font-weight: normal;
    margin: 20px 0;
}
